@import url("https://fonts.googleapis.com/css2?family=DynaPuff:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  background-color: #bfeafd !important;
  font-family: "DynaPuff", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #bfeafd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(86, 153, 175);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(86, 153, 175);
  border-radius: 12px;
}

/* h2  */

h2 {
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 4px !important;
}
button {
  border: none;
  background-color: transparent;
}

@media (max-width: 750px) {
  h2 {
    font-size: 30px;
  }
}
