.storybg {
  background-image: url("../../assets/heroBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.iproad {
  margin: 5%;
}
.iproad h3 {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
}
.iproad {
  font-size: 25px;
  letter-spacing: 2px;
  text-align: left;
  color: #000;
}

/* Team Members  */
.team-container{
  margin-bottom: 5%;
}
.team-container h2 {
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 4px !important;
}

.member-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1150px;
  margin: 40px auto;
  padding: 10px;
  gap: 10px;
}

.member {
  border: 1px solid #2e307d;
  border-radius: 10px;
  background-color: #fff;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  position: relative;
}
.member-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2e307d;
  padding: 10px 30px;
  height: 110px;
}

.name1 {
  width: calc(55%);
  padding-right: 20px;
  border-right: 2px solid #2e307d;
  justify-content: space-between;
  display: flex;
  align-items: center;
  color: #2e307d;
  font-size: 1em;
  line-height: 1.4;
}
.name2 {
  width: calc(45%);
  padding-left: 20px;
  color: #2e307d;
  font-size: 1em;
  line-height: 1.4;
}

.flip-card {
  width: 100%;
  height: 340px;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  padding: 20px 40px;
}

.flip-card:hover {
  transform: rotateY(180deg);
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
  border-radius: 20px;
  padding: 4px;
  background-color: #bfeafd;
  color: #000;
}
.flip-card-back h6 {
  font-size: 11px;
  text-align: center;
}

.member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: opacity 0.7s cubic-bezier(0.23, 1, 0.32, 1);
}

.round-social-wrapper {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ff6d54;
  transition: background-color 0.5s ease;
  margin: 10px auto;
}
.round-social-wrapper .twitterIcon {
  width: 80%;
  height: 80%;
  color: #fff;
}

@media (max-width: 968px) {
  .member {
    width: calc(50% - 20px);
  }
}
@media (max-width: 750px) {
  .member {
    width: 100%;
  }
  .iproad p {
    font-size: 20px !important;
  }
}

@media (max-width: 450px) {
  .flip-card {
    padding: 20px;
  }
}
