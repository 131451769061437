.subheading {
  font-size: 25px;
  letter-spacing: 2px;
  color: #000;
  margin-bottom: 2%;
  text-align: center;
}

.parentmap {
  position: relative;
}

.mapbg {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 5%;
  width: 100%;
  z-index: 0;
}

.mapfimg {
  position: absolute;
  top: 35%;
  left: 5%;
  width: 90%;
  z-index: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.mapmimg {
  position: absolute;
  top: 4%;
  left: -1%;
  width: 94%;
  z-index: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.mapwimg {
  position: absolute;
  top: 48.2%;
  left: -5%;
  width: 33%;
  z-index: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.mapdimg {
  position: absolute;
  top: 35.6%;
  left: 10.8%;
  width: 34.5%;
  z-index: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.mapcimg {
  position: absolute;
  top: 51.8%;
  left: 23%;
  width: 43.3%;
  z-index: 0;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}
.mapmimg:hover,
.mapfimg:hover,
.mapwimg:hover,
.mapdimg:hover,
.mapcimg:hover {
  -webkit-filter: drop-shadow(0 0 0.7rem #ffd900);
  filter: drop-shadow(0 0 0.7rem rgb(255, 217, 0));
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  cursor: pointer;
}

/* Modal  */
.popup-overlay {
  position: fixed;
  inset: 0px;
  display: flex;
  z-index: 999;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
}
.popup-content {
  position: relative;
  margin: auto;
  pointer-events: auto;
  z-index: 10001;
  background: #bfeafd;
  width: 50%;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid #d7d7d7;
}
.modal {
  font-size: 12px;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #bfeafd;
  border-radius: 18px;
  border: 1px solid #000;
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid grey;
  font-size: 30px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
  font-size: 18px;
}

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.discord-btn {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 4px !important;
  font-weight: 400 !important;
  font-style: normal;
  transition: letter-spacing 0.2s ease-in-out !important;
  color: #fff !important;
  background: #000;
  min-width: 125px !important;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
}

.discord-btn:hover {
  letter-spacing: 6px !important;
  background: radial-gradient(circle, #edf5f8, #bfeafd);
  color: #000 !important;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5), 0 0 20px 20px #90dafa;
}

@media (max-width: 950px) {

  .subheading {
    margin-bottom: 50px;
  }
  
  .popup-content {
    width: 70%;
  }
  .modal > .header {
    font-size: 20px;
  }
  .modal > .content {
    padding: 5px;
    font-size: 10px;
  }
}
