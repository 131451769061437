.footerimg {
  width: 100%;
  margin-bottom: -100px;
}

.footer-socials {
    align-items: center;
    background-color: #0c6e93;
    border-radius: 0 15px 15px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    position: fixed;
    top: 50%;
    width: 60px;
}
.SocailIcon {
  height: 25px;
  font-size: 25px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}
.SocailIcon-adj {
  height: 30px;
}
.SocailIcon:hover {
  opacity: 0.5;
}
